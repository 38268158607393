import Vue from 'vue'
import Vuex from 'vuex'
import pathify, { make } from 'vuex-pathify'


// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import leases from './leases'
import nfts from './nfts'
import modals from './modals'

Vue.use(Vuex)

export default new Vuex.Store({

  // use the plugin
  plugins: [
    pathify.plugin
  ],
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    leases,
    nfts,
    modals,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
