export default [
  // {
  //   path: '/dashboard/analytics',
  //   name: 'dashboard-analytics',
  //   component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  // },
  // {
  //   path: '/dashboard/ecommerce',
  //   name: 'dashboard-ecommerce',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  // },
  // {
  //   path: '/dashboard/org',
  //   name: 'dashboard-org',
  //   component: () => import('@/views/dashboard/org/Org.vue'),
  // },
  // {
  //   path: '/dashboard/onboard',
  //   name: 'dashboard-onboard',
  //   component: () => import('@/views/onboard/Onboard.vue'),
  // },
  {
    path: '/dashboard/app/home',
    name: 'dashboard-app-home',
    component: () => import('@/views/dashboard/app/home.vue'),
  },
]
