import store from '@/store/index'
import { initialAbility, signinUserAbility } from '@/libs/acl/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// laravel handlers
import laravel from "../libs/laravel";
import axios from "axios";

// firebase 
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { getAppType } from './appTypes';
import { saveToLocal } from '@/libs/helpers';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  // return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  return localStorage.getItem('userData') != null;
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))



/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

// sets the cookie and store variable
// function setUserStoreCookie(user) {
//   if (!user.role) {
//     user.role = 'admin';
//   }
//   localStorage.setItem('userData', JSON.stringify(user));
//   // store.set("user/profile", JSON.parse(localStorage.getItem('userData')));
// }

let userProfileUpdate = (user, ability) => {
  console.log("userProfileUpdate: ", user);
  store.state.user.profile = JSON.parse(JSON.stringify(user));
  store.state.user.profile.fullName = user.displayName;
  store.state.user.ability = user.ability ? user.ability : getAppType().ability;

  if (!user.role) {
    user.role = 'admin';
  }

  if (!store.state.user.files) {
    store.set("user/files", []);
  }

  saveToLocal("userData", store.state.user);
  ability.update(store.state.user.ability)
}


export const emailSignup = (name, userEmail, password, ability, router, toast) => {
  return new Promise((resolve, reject) => {
    if (laravel.use) {
      laravelEmailSignup(name, userEmail, password, ability, router, toast, resolve, reject);
    } else {
      firebaseEmailSignup(name, userEmail, password, ability, router, toast, resolve, reject);
    }
  })
}


//firebase submit
function firebaseEmailSignup(name, userEmail, password, ability, router, toast, resolve, reject) {
  firebase
    .auth()
    .createUserWithEmailAndPassword(userEmail, password)
    .then(data => {
      console.log("submit auth data:", data.user);
      data.user
        .updateProfile({
          displayName: name
        })
        .then(() => {
          // send email verification
          if (!data.user.emailVerified) {
            data.user.sendEmailVerification().then(function () {
              console.log('send Verification');
            }, function (error) {
              console.log('not send Verification');
            });
          }
          userProfileUpdate(data.user, ability);
          resolve(data.user);
        });
    })
    .catch(err => {
      console.log("error submit", err.message);
      reject(err.message);
    });
}


export const emailLogin = (userEmail, password, ability) => {
  if (laravel.use) {
    return laravelEmailLogin(userEmail, password, ability);
  } else {
    return firebaseEmailLogin(userEmail, password, ability);
  }
}

//firebase submit
var firebaseEmailLogin = (userEmail, password, ability) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(userEmail, password)
      .then(data => {
        console.log("submit auth data:", data.user);
        // send email verification
        if (!data.user.emailVerified) {
          data.user.sendEmailVerification().then(function () {
            console.log('send Verification');
          }, function (error) {
            console.log('not send Verification');
          });
        }
        userProfileUpdate(data.user, ability);
        resolve(data.user)
      })
      .catch(err => {
        console.log("error submit", err.message);
        reject(err);
      });
  });
}


export const googleLogin = (ability, router, toast) => {
  if (laravel.use) {
    return laravelGoogleLogin(ability, router, toast);
  } else {
    return firebaseGoogleLogin(ability, router, toast);
  }
}

var firebaseGoogleLogin = (ability, router, toast) => {
  let provider = new firebase.auth.GoogleAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      let token = result.credential.accessToken;
      let rtoken = result.credential.refreshToken;
      let user = result.user;
      console.log(token) // Token
      console.log(rtoken) // Token
      console.log(user) // User that was authenticated    
      userProfileUpdate(user, ability);
      //   console.log("user af: ", JSON.parse(localStorage.getItem('userData')));
      router.push({ path: '/' });
    })
    .catch((err) => {
      console.log(err); // This will give you all the information needed to further debug any errors
      toast({
        component: ToastificationContent,
        props: {
          title: 'Google SignIn failed',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    });
}


export const sendPasswordResetEmail = (userEmail) => {
  if (laravel.use) {
    return laravelSendPasswordResetEmail(userEmail);
  } else {
    return firebaseSendPasswordResetEmail(userEmail);
  }
}

var firebaseSendPasswordResetEmail = (userEmail) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .sendPasswordResetEmail(userEmail)
      .then(() => {
        console.log("email sent")
        resolve(userEmail);
      })
      .catch(error => {
        console.log(error)
        reject(error);
      });
  });
}



export const logout = (ability, router, toast) => {
  if (laravel.use) {
    return laravelLogout(ability, router, toast);
  } else {
    return firebaseLogout(ability, router, toast);
  }
}

function logoutSuccess(ability, router, toast) {
  // Remove userData from localStorage
  localStorage.removeItem('userData');

  // Reset ability
  ability.update(initialAbility)

  // Redirect to login page
  router.push({ name: 'auth-login' })
  console.log("logout sucess");
}


function firebaseLogout(ability, router, toast) {

  // firebase logout
  firebase.auth().signOut().then(function () {
    // Sign-out successful.
    logoutSuccess(ability, router, toast);

  }, function (error) {
    // An error happened.
    toast({
      component: ToastificationContent,
      props: {
        title: 'Logout error',
        icon: 'EditIcon',
        variant: 'danger',
      },
    });
    console.log("logout failed");
  });

}

export const refreshUserProfile = (store, ability) => {
  return new Promise((resolve, reject) => {
    if (laravel.use) {
      laravelRefreshUserProfile(store, ability)
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      firebaseRefreshUserProfile(store, ability);
    }
  });
}

function firebaseRefreshUserProfile(store, ability) {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((userAuth) => {
      if (userAuth) {
        console.log("user: ", userAuth);
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((tokenResult) => {
            console.log(tokenResult.claims);
            // store.set("user/profile", tokenResult.claims);
            userProfileUpdate(tokenResult.claims, ability);
            resolve(tokenResult.claims)
          })
          .catch((err) => {
            console.error("firebaseRefreshUserProfile failed,", err.message);
            reject(err)
          });
      }
    });
  });
}

function laravelRefreshUserProfile(store, ability) {
  // console.log("laravelRefreshUserProfile");
  return new Promise((resolve, reject) => {
    laravel
      .profile()
      .then(function (user) {
        console.log("user profile refresh: ", user);
        user = laravel2FirebaseUser(user);
        // setUserStoreCookie(user);
        userProfileUpdate(user, ability);
        resolve(user);
      })
      .catch((error) => {
        console.log("user profile refresh error: ", error);
        reject(error);
      });
  });

}


/**
 * laravel handling
 */

/**
 * convert laravel user to firebase user
 * @param {user} user 
 * @returns user
 */
function laravel2FirebaseUser(user) {
  user.displayName = user.name;
  user.emailVerified = user.email_verified_at ? true : false;
  user.photoURL = user.profile_photo_path;
  return user;
}


export const initApiProvider = () => {
  if (laravel.use) {
    initLaravelClient();
  }
}

export const initLaravelClient = () => {
  // console.log("initClient");
  laravel.init(axios);
  // console.log("new client: ", laravel.authClient);
}

var laravelEmailSignup = (name, userEmail, password, ability, router, toast, resolve, reject) => {
  let payload = {
    "email": userEmail,
    "password": password,
    "password_confirmation": password,
    "name": name
  }
  console.log("laravel register:", payload);
  laravel
    .register(payload)
    .then(function (user) {
      console.log("signup user: ", user);
      user = laravel2FirebaseUser(user);
      userProfileUpdate(user, ability);
      resolve(user);
    })
    .catch((error) => {
      console.log("register final exception: ", error.message);
      reject(error);
    });
}


var laravelEmailLogin = (userEmail, password, ability) => {

  return new Promise((resolve, reject) => {
    let payload = {
      "email": userEmail,
      "password": password
    }
    // console.log("test login: ", payload);
    laravel
      .login(payload)
      .then(function (user) {
        // console.log("login final then user: ", user);
        user = laravel2FirebaseUser(user);
        userProfileUpdate(user, ability);
        resolve(user);
      })
      .catch((error) => {
        console.log("error login", error.message);
        reject(error);
      });
  });
}


var laravelLogout = (ability, router, toast) => {
  console.log("test logout:");
  laravel
    .logout()
    .then(function (resp) {
      console.log("logout final then resp: ", resp);
      logoutSuccess(ability, router, toast);
    })
    .catch((error) => {
      // console.log("logout final exception: ", error);
      console.log("logout final exception: ", error.message);
      // console.log(this.error);
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error: ' + error.message,
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    });
}


export const laravelGetUser = () => {
  console.log("test profile:");
  laravel
    .profile()
    .then(function (resp) {
      console.log("profile final resp: ", resp);
    })
    .catch((error) => {
      console.log("profile final exception: ", error);
    });
}


var laravelSendPasswordResetEmail = (userEmail) => {
  return new Promise((resolve, reject) => {
    let payload = {
      "email": userEmail
    }
    console.log("test laravelSendPasswordResetEmail: ", payload);
    laravel
      .forgotPassword(payload)
      .then(function (resp) {
        console.log("forgotPassword final then resp: ", resp);
        resolve(resp);
      })
      .catch((error) => {
        console.log("forgotPassword final exception: ", error);
        reject(error);
      });
  });
}


var laravelGoogleLogin = (ability, router, toast) => {
  console.log("laravelGoogleLogin");
}