// import { $toast } from 'vue/types/umd';
import * as countdown from './countdown.min.js'

/**
 * remove element from array if found
 * test:
 * delArrElem([{id:1},{id:2},{id:3}], {id:3}, 'id')
 */
export const delArrElem = (arr, elem, key) => {
  let delInd = -1;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] == elem[key]) {
      delInd = i;
      break
    }
  }
  if (delInd >= 0)
    arr.splice(delInd, 1);

  return arr;
}


export const getNameFromFileObj = (file) => {
  console.log("getNameFromFileObj:", file);
  return file.filename.match(/\d+_(.*)/i)[1];
}


export const isCreatorAnEntity = (user, entities) => {
  for (let i = 0; i < entities.length; i++) {
    if (entities[i].email == user.email) {
      return true;
    }
  }
  return false;
}


// store object to cookie 
export const saveToLocal = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
}

export const getLocal = (key) => {
  return JSON.parse(localStorage.getItem(key));
}

export const timeToGo = (start_date, end_date) => {
  let dd = countdown(new Date(start_date), new Date(end_date)); //.toString();
  // console.log("ttg: ", dd);
  return (dd.years && dd.years > 0 ? dd.years + ' yrs, ' : '')
    + (dd.months && dd.months > 0 ? dd.months + ' mths, ' : '')
    + (dd.days && dd.days > 0 ? dd.days + ' days' : '')
    ;
}

export const timeToGoDays = (start_date, end_date) => {
  let dd = countdown(new Date(start_date), new Date(end_date), countdown.DAYS); //.toString();
  // console.log("ttg units: ", dd.units);
  return dd;
}


export const getArrElemById = (arr, key, idval) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] == idval) {
      return arr[i];
    }
  }
}


export const updateArrElemById = (arr, key, id, newObj) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] == id) {
      arr[i] = newObj;
      return;
    }
  }
}

/*
// formats the lease return object into form data 
// + sort entities array into leaseors, leasees and guarantors

"entityActAsTypes": [
  {
    "value": null,
    "text": "Please select"
  },
  {
    "value": "leasor",
    "text": "Leasor"
  },
  {
    "value": "leasee",
    "text": "leasee"
  },
  {
    "value": "guarantor",
    "text": "Guarantor"
  }
],
*/
export const sortEntitiesArrayByType = (entities) => {
  let data = {
    "leasors": [],
    "leasees": [],
    "guarantors": [],
  }

  if (!entities) {
    return data;
  }

  for (let i = 0; i < entities.length; i++) {
    switch (entities[i].act_as) {
      case 'leasor':
        data.leasors.push(entities[i]);
        break;
      case 'leasee':
        data.leasees.push(entities[i]);
        break;
      case 'guarantor':
        data.guarantors.push(entities[i]);
        break;
      default:
        break;
    }
  }

  return data;
}


export const showInfoModal = (bvModal, data) => {
  bvModal
    .msgBoxConfirm(data.body,
      {
        title: data.title,
        size: 'lg',
        variant: "ok-only",
        okVariant: 'info',
        // okTitle: 'Close',
        // cancelTitle: 'No',
        // cancelVariant: 'none',
        hideHeaderClose: false,
        centered: true
      })
    .then(value => {
      this.boxTwo = value
    })
}

export const findValueInArray = (arr, value) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] == value) {
      return true;
    }
  }
}

export const getErrorMessage = (errorStr) => {
  if (errorStr.includes('401')) {
    return 'You are not authorized';
  } else {
    return '';
  }
}

