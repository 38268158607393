<template>
  <b-form-group :label="title" label-for="summaryvaluespan">
    <span id="est-value-all-nft-supply" class="h5 text-uppercase">
      {{ format(value) }}
    </span>
  </b-form-group>
</template>

<script>
import { BRow, BCol, BTable, BCardText, BFormGroup } from "bootstrap-vue";
import { dollarUSLocale } from "@/libs/financeHelper";

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BCardText,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "number",
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    format(value) {
      switch (this.type) {
        case "number":
          return dollarUSLocale.format(value);
          break;
        case "money":
          return this.$t("currencySign") + dollarUSLocale.format(value);
          break;

        case "percent":
          return dollarUSLocale.format(value) + "%";
          break;

        case "upper":
          return value.toUpperCase();
          break;

        default:
          break;
      }
    },
  },
};
</script>