<template>
  <!-- <b-card-code title="Repeating Forms"> -->
  <!-- select 2 demo -->
  <b-modal
    id="sellNftModalId"
    :title="title"
    :ok-title="$t('Confirm')"
    cancel-variant="outline-secondary"
  >
    <b-row>
      <b-col cols="6">
        <b-form-group
          :label="$t('total_nft_available_for_allocation')"
          label-for="est-value-all-nft-supply"
        >
          <span id="est-value-all-nft-supply" class="h5 text-uppercase">
            {{ formatMoney(nft && nft.supply ? nft.supply : 0) }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('NFT Contract Type')"
          label-for="est-value-all-nft-contract_type"
        >
          <span id="est-value-all-nft-contract_type" class="h5 text-uppercase">
            {{ nft && nft.type ? nft.type : "" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('Estimated Total Value')"
          label-for="est-value-all-nft"
        >
          <span id="est-value-all-nft" class="h5 text-uppercase">
            {{ $t("currencySign") }}
            {{ formatMoney(estTotalValue) }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Lease Amount')" label-for="v-lease-amount">
          <span class="h5">
            {{ $t("currencySign") }}
            {{ amount }}
            /
            {{ freq }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <hr />
      </b-col>

      <b-col cols="6">
        <summary-value
          :title="$t('Your NFTs available to Sell')"
          :value="selfHolding"
          type="number"
        />
      </b-col>

      <b-col cols="6">
        <summary-value
          :title="'%'"
          :value="selfHoldingPercent"
          type="percent"
        />
      </b-col>

      <b-col cols="6">
        <summary-value
          :title="$t('Est Holding Value')"
          :value="showValuePerNft * selfHolding"
          type="money"
        />
      </b-col>

      <b-col cols="6">
        <summary-value-per
          :title="$t('Est Holding Income')"
          :value="showNftIncomePerFreqHolding"
          :freq="freq ? freq : ''"
          type="money"
        />
      </b-col>

      <b-col cols="12">
        <b-form-group :label="$t('Number of NFTs')" label-for="largeInput">
          <b-form-input
            id="largeInput"
            size="lg"
            type="number"
            :placeholder="$t('size of order')"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group :label="$t('Sell Price')" label-for="largeInput">
          <b-form-input
            id="largeInput"
            size="lg"
            type="number"
            :placeholder="$t('price per nft')"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <h1>Functionality Pending</h1>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BModal,
  VBModal,
} from "bootstrap-vue";
// import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { cloneStruct } from "@/@core/utils/utils";
import entity from "@/libs/struct/entity";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SummaryValue from "@/views/card/app-custom/summary-value.vue";
import SummaryValuePer from "@/views/card/app-custom/summary-value-per.vue";

import { sync } from "vuex-pathify";

import {
  estLeaseValue,
  dollarUSLocale,
  estValuePerNft,
  estIncomePerNftPerFreq,
  leaseDaysLeft,
  getMyHolding,
} from "@/libs/financeHelper";

import laravel from "@/libs/laravel";

export default {
  components: {
    BFormSelect,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    VBModal,
    SummaryValue,
    SummaryValuePer,
  },
  directives: {
    Ripple,
  },
  // mixins: [heightTransition],
  props: {
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  computed: {
    nft: sync("modals/sellNftModal@nft"),
    lease: sync("modals/sellNftModal@lease"),
    user: sync("user/profile"),

    // nft holding this entity holds for this lease nft
    selfHolding() {
      // entity object that
      if (!this.user || !this.lease || !this.nft) {
        return 0;
      }
      let hold = getMyHolding(this.user, this.lease, this.nft);
      return hold && hold.holding ? hold.holding : 0;
    },
    selfHoldingPercent() {
      if (!this.nft) {
        return 0;
      }
      // let supply = this.nft.supply ? this.nft.supply : 0;
      // this.selfHolding;
      return (this.selfHolding * 100) / (this.nft.supply ? this.nft.supply : 1);
    },

    showNftIncomePerFreqHolding() {
      return this.showNftIncomePerFreq * this.selfHolding;
    },
    showNftIncomePerFreq() {
      let val = estIncomePerNftPerFreq(
        this.showValuePerNft,
        this.daysLeft,
        this.freq
      );

      console.log(
        "showValuePerNft, daysLeft, freq, val: ",
        this.showValuePerNft,
        this.daysLeft,
        this.freq,
        val
      );

      return val;
    },
    freq() {
      return this.lease && this.lease.paym_freq ? this.lease.paym_freq : "";
    },
    amount() {
      return this.lease && this.lease.paym_freq_amt
        ? this.lease.paym_freq_amt
        : 0;
    },
    daysLeft() {
      if (!this.lease || !this.lease.start_date) return 0;
      return leaseDaysLeft(this.lease);
    },
    leaseTimeLeft() {
      if (this.lease && this.lease.start_date && this.lease.end_date) {
        return timeToGo(this.lease.start_date, this.lease.end_date);
      } else {
        return "";
      }
    },
    estTotalValue() {
      if (this.lease) return estLeaseValue(this.lease);
      else return 0;
    },
    showValuePerNft() {
      // console.log("show value per nft : ", );
      if (!this.nft) {
        return 0;
      }
      return estValuePerNft(this.estTotalValue, this.nft.supply);
    },
  },
  data() {
    return {
      // nft: null,
    };
  },
  watch: {
    // buyNftModal(oldData, newData) {
    //   this.nft = newData.nft;
    // },
  },
  mounted() {},
  created() {},
  destroyed() {},
  methods: {
    formatMoney(amt) {
      return dollarUSLocale.format(amt);
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
