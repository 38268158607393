/**
 * tells app if its sponsor, org or volunteer user domain
 * returns a dahsboard route and acl ability 
 * 
 * Note: rules can be improved a little later.  
 * For now, if a navigationmenu item defines a 
 * e.g., resource: 'org', action: 'read', 
 * then it is exclusive of that role
 */
export const appTypes = {
  "app": {
    type: "app",
    route: {name : "dashboard-app-home"},
    ability: [
      {
        action: 'manage',
        subject: 'all'
      }
    ],
  }, 
  "org": {
    type: "org",
    route: {name : "dashboard-org"},
    ability: [
        {
            action: 'manage',
            subject: 'all'
        },
        {
            action: 'read',
            subject: 'sponsor',
            inverted: true
        },
        {
            action: 'read',
            subject: 'volunteer',
            inverted: true
        }
    ],
  }, 
  "sponsor": {
    type: "sponsor",
    route: {name : "dashboard-sponsor"},
    ability: [
      {
        action: 'manage',
        subject: 'all',
      },
      {
          action: 'read',
          subject: 'org',
          inverted: true
      },
      {
          action: 'read',
          subject: 'volunteer',
          inverted: true
      }
    ],
  }, 
  "volunteer": {
    type: "volunteer",
    route: {name : "dashboard-volunteer"},
    ability: [
      {
        action: 'manage',
        subject: 'all',
      },
      {
          action: 'read',
          subject: 'sponsor',
          inverted: true
      },
      {
          action: 'read',
          subject: 'org',
          inverted: true
      }
    ],
  }
}


// default app 
export const getAppType = (() => {
  return appTypes.app; 
});

// export const getAppType = (() => {
//     let hostname = window.location.hostname;
//       console.log("hostname: ", window.location.hostname)
  
//       if (hostname.includes("org")) {
//         return appTypes.org;
      
//       } else if (hostname.includes("sponsor")) {
//         // next({name : "dashboard-sponsor"})
//         return appTypes.sponsor;
  
//       } else if (hostname.includes("volunteer")) {
//         // next({name : "dashboard-volunteer"})
//         return appTypes.volunteer;
  
//       } else {
//         // redirect to onboarding screen
//         // next({name : "dashboard-org"})
//         return appTypes.org;
//       }
//   })
  