export default {
    "type" : null, 
    "fullname" : "", 
    "taxid" : "",
    "entityIdType": null, 
    "entityId": "", 
    "address" : "", 
    "phone" : "", 
    "email" : "", 
    "contact_person": ""
}