// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var laravelConfig = {
  useLaravel: true,
  url: "https://api.lease2nft.app",
  // url: "http://localhost:8000",
  endpoints: {
    login: "/api/login",
    logout: "/api/logout",
    register: "/api/register",
    profile: "/api/user",
    google_login: "auth/google",
    forgot_password: "api/forgot-password",
    sanctum_csrf_cookie: "/sanctum/csrf-cookie",

    // leases 
    lease_index: "/api/leases",
    lease_store: "/api/leases",
    lease_create: "/api/leases",
    lease_show: "/api/leases/{id}",
    lease_update: "/api/leases/{id}",
    lease_destroy: "/api/leases/{id}",
    lease_edit: "/api/leases/{id}/edit",

    //file  
    files_index: "/api/files",
    files_store: "/api/files",
    files_create: "/api/files",
    files_show: "/api/files/{id}",
    files_update: "/api/files/{id}",
    files_destroy: "/api/files/{id}",
    files_edit: "/api/files/{id}/edit",


    //entities  
    entities_index: "/api/entities",
    entities_store: "/api/entities",
    entities_create: "/api/entities",
    entities_show: "/api/entities/{id}",
    entities_update: "/api/entities/{id}",
    entities_destroy: "/api/entities/{id}",
    entities_edit: "/api/entities/{id}/edit",

    //nfts  
    nfts_index: "/api/nfts",
    nfts_store: "/api/nfts",
    nfts_create: "/api/nfts",
    nfts_show: "/api/nfts/{id}",
    nfts_update: "/api/nfts/{id}",
    nfts_destroy: "/api/nfts/{id}",
    nfts_edit: "/api/nfts/{id}/edit",
    // special route for sending signatures
    nfts_sign: "api/nfts/{nft}/sign/{entity}",
    nfts_portfolio: "api/nfts/portfolio",
    nfts_remind: "api/nfts/{nft}/sign/remind/{entity}",

  }
};
/**
    * 
GET|HEAD        api/leases ................. leases.index › LeaseController@index
POST            api/leases ...................... leases.store › LeaseController@store
GET|HEAD        api/leases/create ............. leases.create › LeaseController@create
GET|HEAD        api/leases/{lease} ................ leases.show › LeaseController@show
PUT|PATCH       api/leases/{lease} ............ leases.update › LeaseController@update
DELETE          api/leases/{lease} .......... leases.destroy › LeaseController@destroy
GET|HEAD        api/leases/{lease}/edit ........... leases.edit › LeaseController@edit

    */

export default laravelConfig; 
