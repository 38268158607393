import laravel from "../libs/laravel";
import store from '@/store/index'

export const init = () => {
  laravel.leases.index()
    .then((resp) => {
      console.log("lease index: ", resp.data);
      // // set store leases data
      // store.state.leases.list.concat(resp.data);
      store.set("leases/list", resp.data);
      console.log("store lease list: ", store.state.leases.list);
    })
    .catch((error) => {
      console.error("lease index faild: ", error.message)
    });
}