import laravel from "../libs/laravel";
import store from '@/store/index'

export const init = () => {
  laravel.nfts.index()
    .then((resp) => {
      console.log("nft index: ", resp.data);
      // // set store leases data
      // store.state.leases.list.concat(resp.data);
      store.set("nfts/list", resp.data);
      console.log("store nfts list: ", store.state.nfts.list);
    })
    .catch((error) => {
      console.error("nfts index faild: ", error.message)
    });

  laravel.nfts.portfolio()
    .then((resp) => {
      console.log("nft portfolio: ", resp.data);
      // // set store leases data
      // store.state.leases.list.concat(resp.data);
      store.set("nfts/portfolio", resp.data);
      console.log("store nfts portfolio: ", store.state.nfts.portfolio);
    })
    .catch((error) => {
      console.error("nfts index faild: ", error.message)
    });
}

