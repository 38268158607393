<template>
  <!-- <b-card-code title="Repeating Forms"> -->
  <!-- select 2 demo -->
  <b-modal
    id="buyNftModalId"
    :title="title"
    :ok-title="$t('Confirm')"
    cancel-variant="outline-secondary"
  >
    <b-row>
      <b-col cols="6">
        <b-form-group
          :label="$t('total_nft_available_for_allocation')"
          label-for="est-value-all-nft-supply"
        >
          <span id="est-value-all-nft-supply" class="h5 text-uppercase">
            {{
              formatMoney(
                buyNftModal.nft && buyNftModal.nft.supply
                  ? buyNftModal.nft.supply
                  : 0
              )
            }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('NFT Contract Type')"
          label-for="est-value-all-nft-contract_type"
        >
          <span id="est-value-all-nft-contract_type" class="h5 text-uppercase">
            {{
              buyNftModal.nft && buyNftModal.nft.type
                ? buyNftModal.nft.type
                : ""
            }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('Estimated Total Value')"
          label-for="est-value-all-nft"
        >
          <span id="est-value-all-nft" class="h5 text-uppercase">
            {{ $t("currencySign") }}
            {{ formatMoney(estTotalValue) }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Lease Amount')" label-for="v-lease-amount">
          <span class="h5">
            {{ $t("currencySign") }}
            {{ amount }}
            /
            {{ freq }}
          </span>
        </b-form-group>
      </b-col>

      <!-- <b-col cols="12">
        <b-form-group
          :label="$t('Number of NFTs to Order')"
          label-for="largeInput"
        >
          <b-form-input
            id="largeInput"
            size="lg"
            :placeholder="$t('size of order')"
          />
        </b-form-group>
      </b-col> -->

      <b-col cols="12">
        <b-list-group>
          <b-list-group-item v-for="order in orderBook" :key="order.price">
            <div class="row">
              <b-form-group
                class="col-3 col-sm-3"
                :label="$t('Seller Price')"
                label-for="largeInput"
              >
                {{ $t("currencySign") + formatMoney(order.price) }}
              </b-form-group>

              <b-form-group
                class="col-3 col-sm-3"
                :label="$t('Seller Qty')"
                label-for="largeInput"
              >
                {{ formatMoney(order.size) }}
              </b-form-group>

              <b-form-group
                class="col-6 col-sm-6"
                :label="$t('Your Qty')"
                label-for="largeInput"
              >
                <b-form-input
                  id="largeInput"
                  size="md"
                  :placeholder="$t('your size')"
                />
              </b-form-group>

              <!-- <b-form-group
                class="col-2 col-sm-2"
                :label="$t('Buy')"
                label-for="largeInput"
              >
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success"
                >
                  {{ $t("Buy Size") }}
                </b-button>
              </b-form-group> -->
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
// import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { cloneStruct } from "@/@core/utils/utils";
import entity from "@/libs/struct/entity";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { sync } from "vuex-pathify";

import {
  estLeaseValue,
  dollarUSLocale,
  estValuePerNft,
  estIncomePerNftPerFreq,
  leaseDaysLeft,
  getMyHolding,
} from "@/libs/financeHelper";

import laravel from "@/libs/laravel";

export default {
  components: {
    BFormSelect,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    VBModal,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  // mixins: [heightTransition],
  props: {
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  computed: {
    buyNftModal: sync("modals/buyNftModal"),
    lease: sync("modals/buyNftModal@lease"),
    showNftIncomePerFreqHolding() {
      return this.showNftIncomePerFreq * this.selfHolding;
    },
    showNftIncomePerFreq() {
      let val = estIncomePerNftPerFreq(
        this.showValuePerNft,
        this.daysLeft,
        this.freq
      );

      console.log(
        "showValuePerNft, daysLeft, freq, val: ",
        this.showValuePerNft,
        this.daysLeft,
        this.freq,
        val
      );

      return val;
    },
    freq() {
      return this.lease && this.lease.paym_freq ? this.lease.paym_freq : "";
    },
    amount() {
      return this.lease && this.lease.paym_freq_amt
        ? this.lease.paym_freq_amt
        : 0;
    },
    daysLeft() {
      if (!this.lease || !this.lease.start_date) return 0;
      return leaseDaysLeft(this.lease);
    },
    leaseTimeLeft() {
      if (this.lease && this.lease.start_date && this.lease.end_date) {
        return timeToGo(this.lease.start_date, this.lease.end_date);
      } else {
        return "";
      }
    },
    estTotalValue() {
      if (this.lease) return estLeaseValue(this.lease);
      else return 0;
    },
    showValuePerNft() {
      // console.log("show value per nft : ", );
      return estValuePerNft(this.estTotalValue, this.buyNftModal.nft.supply);
    },
  },
  data() {
    return {
      // nft: null,
      orderBook: [
        {
          size: 1000,
          price: 1.25,
        },

        {
          size: 5000,
          price: 2.5,
        },
        {
          size: 12000,
          price: 1.1,
        },
      ],
    };
  },
  watch: {
    // buyNftModal(oldData, newData) {
    //   this.nft = newData.nft;
    // },
  },
  mounted() {},
  created() {},
  destroyed() {},
  methods: {
    formatMoney(amt) {
      return dollarUSLocale.format(amt);
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
