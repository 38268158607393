import { timeToGoDays, getArrElemById } from './helpers.js';

export const dollarUSLocale = Intl.NumberFormat('en-US',
  {
    maximumSignificantDigits: 4,
    minimumSignificantDigits: 2
  });

/**
 * 
 *"leasePaymentFreqList": [
    {
      "value": "daily",
      "text": "Daily"
    },
    {
      "value": "weekly",
      "text": "weekly"
    },
    {
      "value": "monthly",
      "text": "monthly"
    },
    {
      "value": "y2y",
      "text": "Year to Year"
    }
  ],} lease 
 */
export const getAmountPerDay = (amt, freq) => {
  // console.log("getAmountPerDay: ", amt, freq);
  let amtperday = 0;
  switch (freq) {
    case "daily":
      amtperday = amt;
      break;

    case "weekly":
      amtperday = amt / 7;
      break;

    case "monthly":
      amtperday = amt * 12 / 365;
      break;

    case "y2y":
      amtperday = amt / 365;
      break;

    default:
      break;
  }
  return amtperday;
}

export const getAmountPerFreqFromDay = (amtPerDay, freq) => {
  // console.log("getAmountPerFreq: ", amtPerDay, freq);
  let final = 0;
  switch (freq) {
    case "daily":
      final = amtPerDay;
      break;

    case "weekly":
      final = amtPerDay * 7;
      break;

    case "monthly":
      final = amtPerDay * 365 / 12;
      break;

    case "y2y":
      final = amt * 365;
      break;

    default:
      break;
  }
  return final;
}


export const leaseDaysLeft = (lease) => {
  let tdg = timeToGoDays(lease.start_date, lease.end_date)
  return tdg.days;
}

export const estLeaseValue = (lease) => {
  // console.log("estLeaseValue: ", lease);
  let tdg = timeToGoDays(lease.start_date, lease.end_date)
  let days = tdg.days;

  let amtperday = getAmountPerDay(lease.paym_freq_amt, lease.paym_freq);
  let totalvalue = amtperday * days;
  // console.log("pay_freq_amt, pay_freq: ", lease.paym_freq_amt, lease.paym_freq, " days: ", days);
  console.log("estimated total value: ", totalvalue);
  return totalvalue;
}

export const estValuePerNft = (totalvalue, supply) => {
  let val = totalvalue / supply;
  // console.log("estValuePerNft: total: ", totalvalue, " supply: ", supply, " value: ", val);
  return val;
}

export const estIncomePerNftPerFreq = (totalvalue, daysleft, freq) => {
  let amtperday = totalvalue / daysleft;
  return getAmountPerFreqFromDay(amtperday, freq);
}


// returns holding object 
// belong to lease entity of the user
export const getMyHolding = (user, lease, nft) => {

  if (!user || !lease || !lease.entities
    || !nft || !nft.holdings) {
    return;
  }
  console.log("getMyHolding: ", user, lease, nft.holdings);

  let myEnt = getArrElemById(lease.entities, 'user_id', user.id);
  let myHold = null;
  console.log("found myent: ", myEnt);
  if (myEnt && myEnt.id) {
    myHold = getArrElemById(nft.holdings, 'entity_id', myEnt.id);
    console.log("found myhold: ", myHold);
  }

  return myHold;
}