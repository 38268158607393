import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { firebaseConfig } from './firebase.env';


// Initialize Firebase
console.log("firebase config: ", firebaseConfig);
export const db = firebase.initializeApp(firebaseConfig).firestore();


// // Get a Firestore instance
// export const db = firebase
//   // .initializeApp({ projectId: 'MY PROJECT ID' })
//   .firestore()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
db.settings({ timestampsInSnapshots: true })