import * as utils from "@/auth/utils"
import * as leases from "./init_leases"
import * as nfts from "./init_nfts"

/**
 * handle behaviour of app initialisation and loading
 */
export const appload = (store, ability) => {
  return new Promise((resolve, reject) => {
    utils.initApiProvider();
    leases.init();
    nfts.init();
    utils.refreshUserProfile(store, ability)
      .then((resp) => {
        // console.log(user);
        // if logged in, do nothing else
        resolve(resp);
      })
      .catch((error) => {
        console.log(error);
        reject(error)
      });
  });
}

/**
 * app specific before each handling in router.
 * called by router index.js
 * @param {*} to 
 * @param {*} _ 
 * @param {*} next 
 */
export const appBeforeEach = (to, _, next) => {
  console.log("to: ", to)
  // redirect 
  if (!utils.isUserLoggedIn()
    && to.meta.resource != 'Auth'
    // && to.name != 'auth-login' 
  ) {
    return next({ "name": "auth-login" })
  } else {
    return next();
  }
}
