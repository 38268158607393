import { make } from 'vuex-pathify';
import { signinUserAbility } from '@/libs/acl/config';

const state = {
  profile: {
    id: "",
    name: "test",
    email: "",
    photoURL: "",
    emailVerified: ""
  },
  files: [],
  org: {},
  ability: signinUserAbility
}

// make all mutations
const mutations = make.mutations(state)

const actions = {
  // automatically create only `setItems()` action
  ...make.actions(state)
}

const getters = {
  // make all getters (optional)
  ...make.getters(state),
}

export default {
  namespaced: true, // add this if in module
  state,
  getters,
  mutations,
  actions,
}