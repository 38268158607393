export default [
  {
    path: '/manage/leases',
    name: 'manage-leases',
    component: () => import('@/views/pages/leases/ManageLeases.vue'),
    meta: {
      pageTitle: 'Manage',
      breadcrumb: [
        {
          text: 'Leases',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nft/portfolio',
    name: 'nft-portfolio',
    component: () => import('@/views/pages/nfts/NftPortfolio.vue'),
    meta: {
      pageTitle: 'Portfolio',
      breadcrumb: [
        {
          text: 'NFTs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nft/invest',
    name: 'nft-invest',
    component: () => import('@/views/pages/nfts/NftInvest.vue'),
    meta: {
      pageTitle: 'Invest',
      breadcrumb: [
        {
          text: 'NFTs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lease/show/:leaseId',
    name: 'show-lease-details',
    component: () => import('@/views/pages/leases/ShowLeaseDetails.vue'),
    meta: {
      pageTitle: 'Lease',
      breadcrumb: [
        {
          text: 'Lease',
          active: true
        }
      ],
    },
  },
  {
    path: '/lease/show/:leaseId/nft/:nftId',
    name: 'show-nft-details',
    component: () => import('@/views/pages/nfts/ShowNftDetails.vue'),
    meta: {
      pageTitle: 'NFT',
      breadcrumb: [
        {
          text: 'NFT',
          active: true
        }
      ],
    },
  },
  {
    path: '/mint/sign/:nftId/entity/:entityId',
    name: 'entity-sign-mint',
    component: () => import('@/views/pages/nfts/EntitySignMint.vue'),
    meta: {
      pageTitle: 'Sign Mint',
      breadcrumb: [
        {
          text: 'Sign',
          active: true
        }
      ],
    },
  },

  {
    path: '/bank/wallets',
    name: 'bank-wallets',
    component: () => import('@/views/pages/banking/CryptoWallets/CryptoWalletSettingsHome.vue'),
    meta: {
      pageTitle: 'Wallets',
      breadcrumb: [
        {
          text: 'Wallets',
          active: true,
        },
      ],
    },
  },

  {
    path: '/bank/paymentmethods',
    name: 'bank-paymentmethods',
    component: () => import('@/views/pages/banking/PaymentMethods.vue'),
    meta: {
      pageTitle: 'Payment Methods',
      breadcrumb: [
        {
          text: 'Payment',
          active: true,
        },
      ],
    },
  },


  {
    path: '/bank/details',
    name: 'bank-details',
    component: () => import('@/views/pages/banking/BankDetails.vue'),
    meta: {
      pageTitle: 'Bank Accounts',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
      ],
    },
  },
]
