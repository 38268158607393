/**
 * laravel module 
 * instructions 
 * - https://www.digitalocean.com/community/tutorials/vuejs-rest-api-axios
 * - https://github.com/rmartide/vue-google-login
 * npm i axios --save
 */

import config from '@/laravel.env'

var api = {
  use: config.useLaravel,
  authClient: null,
  axiosObj: null,
  config: {
    // required to handle the CSRF token
    withCredentials: true,
    credentials: true,
    headers: {
      // Overwrite Axios's automatically set Content-Type
      // 'Content-Type': 'application/json'
    }
  },
  base_url: config.url,
  urls: config.endpoints,
  fullUrl(url) {
    // console.log("full url: ", this.base_url + url);
    return this.base_url + url;
  },
  init(axiosObj, url) {
    this.axiosObj = axiosObj;
    this.base_url = url ? url : api.base_url;
    // console.log("base url: ", this.base_url);
    this.newClient(this.base_url);
  },
  newClient(url) {
    this.authClient = this.axiosObj.create({
      baseURL: url,
      withCredentials: this.config.withCredentials, // required to handle the CSRF token
      credentials: this.config.credentials,
    });
    return this.authClient;
  },

  // helper util functions
  register(payload) {
    return new Promise((resolve, reject) => {
      api.authClient.get('/sanctum/csrf-cookie')
        .then(function () {
          // console.log("csrf:", resp);
          api.authClient.post(api.urls.register, payload)
            .then(function (resp) {
              console.log("registeer success resp: ", resp)
              resolve(resp.data);
            })
            .catch(error => {
              console.log("ERRRR:: ", error.response.data);
              reject(error.response.data);
            });
        })
    });
  },
  login(payload) {
    return new Promise((resolve, reject) => {
      api.authClient.get('/sanctum/csrf-cookie')
        .then(function () {
          // console.log("csrf:", resp);
          api.authClient
            .post(api.urls.login, payload)
            .then(function (resp) {
              console.log("login response resp: ", resp)
              // return resp;
              resolve(resp.data);
            })
            .catch(error => {
              console.log("ERRRR:: ", error.response.data);
              // return error.response.data;
              reject(error.response.data);
            });
        });
    });
  },
  googleLogin() {
  },
  logout(payload) {
    return new Promise((resolve, reject) => {
      api.authClient.get('/sanctum/csrf-cookie')
        .then(function () {
          // console.log("csrf:", resp);
          api.authClient.post(api.urls.logout, payload)
            .then(function (resp) {
              console.log("logout success resp: ", resp)
              resolve(resp.data);
            })
            .catch(error => {
              console.log("ERRRR:: ", error.response.data);
              reject(error.response.data);
            });
        })
    });
  },
  forgotPassword(payload) {
    return new Promise((resolve, reject) => {
      api.authClient.get('/sanctum/csrf-cookie')
        .then(function () {
          // console.log("csrf:", resp);
          api.authClient
            .post(api.urls.forgot_password, payload)
            .then(function (resp) {
              console.log("forgot_password response resp: ", resp)
              resolve(resp.data);
            })
            .catch(error => {
              console.log("ERRRR:: ", error.response.data);
              // return error.response.data;
              reject(error.response.data);
            });
        });
    });
  },
  emailLoginLink() {
  },
  profile() {
    return new Promise((resolve, reject) => {
      api.authClient.get(api.urls.profile)
        .then(function (resp) {
          console.log("user resp: ", resp)
          resolve(resp.data);
        })
        .catch(error => {
          console.log("ERRRR:: ", error.response.data);
          reject(error.response.data);
        });
    });
  },

  // files 
  files: {
    upload: function (file, leaseId, desc) {
      return new Promise((resolve, reject) => {
        console.log("laravel upload: ", file);
        let formData = new FormData();
        formData.append('file', file);
        formData.append('desc', desc);
        if (leaseId > 0) {
          formData.append('lease_id', leaseId);
        }

        api.authClient.post(
          api.urls.files_store,
          formData,
          {
            headers: { 'content-type': 'multipart/form-data' }
          })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    destroy: function (file) {
      return new Promise((resolve, reject) => {
        if (!file || !file.id) {
          reject({ message: "invalid parameters" });
        }
        let url = api.urls.files_destroy.replace("{id}", file.id)
        console.log("destroy url: ", url);
        api.authClient.delete(url)
          .then(function (resp) {
            console.log("destroy file: ", resp)
            if (resp.data.success) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },
  // lease resources handling functions 
  entities: {
    show(entityId) {
      return new Promise((resolve, reject) => {
        let url = api.urls.entities_show.replace("{id}", entityId)
        api.authClient.get(url)
          .then(function (resp) {
            console.log("entity resp: ", resp)
            resolve(resp.data);
          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
    destroy: function (entityId) {
      return new Promise((resolve, reject) => {
        let url = api.urls.entities_destroy.replace("{id}", entityId);
        api.authClient.delete(url)
          .then(function (resp) {
            console.log("entities del: ", resp)
            if (resp.data.success) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
    update: function () {
    },
  },

  nfts: {
    remind(nftId, entityId) {
      return new Promise((resolve, reject) => {
        let url = api.urls.nfts_remind.replace("{nft}", nftId);
        url = url.replace("{entity}", entityId);
        console.log("send remind: ".url);
        api.authClient.get(url)
          .then(function (resp) {
            console.log("entity remind resp: ", resp)
            resolve(resp.data);
          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
    portfolio: function () {
      return new Promise((resolve, reject) => {
        api.authClient.get(api.urls.nfts_portfolio)
          .then(function (resp) {
            console.log("nfts portfolio: ", resp)
            if (resp.data.success) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
    sign(nftId, entityId, payload) {
      return new Promise((resolve, reject) => {
        let url = api.urls.nfts_sign.replace("{nft}", nftId);
        url = url.replace("{entity}", entityId);
        api.authClient.post(url, payload)
          .then(function (resp) {
            console.log("user resp: ", resp)
            resolve(resp.data);
          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
    show(nftId) {
      return new Promise((resolve, reject) => {
        let url = api.urls.nfts_show.replace("{id}", nftId)
        api.authClient.get(url)
          .then(function (resp) {
            console.log("user resp: ", resp)
            resolve(resp.data);
          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
    index: function () {
      return new Promise((resolve, reject) => {
        api.authClient.get(api.urls.nfts_index)
          .then(function (resp) {
            console.log("nfts index: ", resp)
            if (resp.data.success) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
    store: function (payload) {
      // payload
      return new Promise((resolve, reject) => {
        console.log(api.urls.nfts_store, " payload:", payload);
        api.authClient.post(api.urls.nfts_store, payload)
          .then(function (resp) {
            console.log("nfts store: ", resp)
            if (resp.data.success) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch(error => {
            console.log("ERRRR:: ", error);
            reject(error);
          });
      });
    },
  },


  // lease resources handling functions 
  leases: {
    destroy: function (leaseId) {
      return new Promise((resolve, reject) => {
        let url = api.urls.lease_destroy.replace("{id}", leaseId);
        api.authClient.delete(url)
          .then(function (resp) {
            console.log("lease del: ", resp)
            if (resp.data.success) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
    index: function () {
      return new Promise((resolve, reject) => {
        api.authClient.get(api.urls.lease_index)
          .then(function (resp) {
            console.log("lease index: ", resp)
            if (resp.data.success) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }

          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
    store: function (payload) {
      // payload
      return new Promise((resolve, reject) => {
        console.log(api.urls.lease_store, " payload:", payload);
        api.authClient.post(api.urls.lease_store, payload)
          .then(function (resp) {
            console.log("lease index: ", resp)
            if (resp.data.success) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch(error => {
            console.log("ERRRR:: ", error);
            reject(error);
          });
      });
    },
    update: function (payload) {
      // payload
      return new Promise((resolve, reject) => {
        console.log(api.urls.lease_update, " payload:", payload);
        let url = api.urls.lease_update.replace("{id}", payload.id);
        api.authClient.put(url, payload)
          .then(function (resp) {
            console.log("lease index: ", resp)
            if (resp.data.success) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch(error => {
            console.log("ERRRR:: ", error);
            reject(error);
          });
      });
    },
    show(leaseId) {
      return new Promise((resolve, reject) => {
        let url = api.urls.lease_show.replace("{id}", leaseId)
        api.authClient.get(url)
          .then(function (resp) {
            console.log("user resp: ", resp)
            resolve(resp.data);
          })
          .catch(error => {
            console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  }

}

export default api;

