// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyD6EPPSddx4dGUy35p4V14bB1hdQAL52L4",
  authDomain: "app-lease2nft-com.firebaseapp.com",
  projectId: "app-lease2nft-com",
  storageBucket: "app-lease2nft-com.appspot.com",
  messagingSenderId: "762640495597",
  appId: "1:762640495597:web:7eab3260e67dc573a92de4",
  measurementId: "G-23XK6SKWKW"
};


